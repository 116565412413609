import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../components/Layout'

import { StaticImage } from 'gatsby-plugin-image'

export default function About() {
  return (
    <Layout>
      <Container>
      <h1>Tentang Easy Umroh</h1>
        <p>Web Easy Umroh ini merupakan tempat menuliskan perjalanan umroh kami yang kami bagikan
        kepada pembaca untuk memberikan inspirasi, semoga para pembaca dapat juga segera dimudahkan oleh
        Allah SWT untuk berkunjung tanah suci Mekkah dan Madinah. Aamiin Allahumma Aamiin.</p>
        <p>Pastikan Anda umroh bersama kami di Easy Umroh by Tanur Muthmainnah, dimudahkan, dilayani, 
        insya Allah menjadi Umroh Mabrur.</p>
      <StaticImage 
        src='../images/about/author-easy-umroh.png'
          alt='Penulis Blog Easy Umroh'
          placeholder='blurred'
          layout='fixed'
          width={350}
          height={350}
      />
      <br />
      </Container>
    </Layout>
  )
}